import {useEffect} from 'react'

import {useLang} from '@_metronic/i18n/Metronici18n'
import {useAuth} from '@app/modules/auth'

const RU_PIXELS = {
  default: 'https://stepform.io/notify/pixel/4dp0fjnikbl9bwc34l1851n62sokimhe',
  premium: 'https://stepform.io/notify/pixel/yyhzdi77if3q0caq6wxcgj5cfltjw2zt',
}

const EN_PIXELS = {
  default: 'https://stepform.io/notify/pixel/picxtw6avam7g5nvzm3hn8i7ahaav2i6',
  premium: 'https://stepform.io/notify/pixel/fc95i1ptokno6yjy5c1utpkri3oavly5',
}

const usePixelsInsert = () => {
  const {currentUser} = useAuth()
  const language = useLang()

  useEffect(() => {
    const langPixels = language === 'ru' ? RU_PIXELS : EN_PIXELS
    // console.log('### langPixels', langPixels)

    const timeout = setTimeout(() => {
      if (currentUser) {
        const defaultScript = document.createElement('script')
        defaultScript.defer = true
        defaultScript.src = langPixels.default

        document.head.appendChild(defaultScript)

        if (!currentUser.is_premium) {
          const premiumScript = document.createElement('script')
          premiumScript.defer = true
          premiumScript.src = langPixels.premium

          document.head.appendChild(premiumScript)
        }
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
    }
  }, [currentUser, language])
}

export default usePixelsInsert
