import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

import {PersonModel, TreeModel} from '@api/models'
import {editPersonAx} from '@api/requests'

import useFirstLetters from '@components/organisms/Tree/hooks/useFirstLetters'
import useSessionTs from '@hooks/useSessionTs'

import {usePersonsStore} from '@store/persons'
import {useSubTreeModalStore} from '@components/molecules/SubTreeModal/SubTreeModal'

import {ReactComponent as TrashIcon} from '@assets/icons/svg/trash-icon.svg'

type PersonInfoBlockProps = {treeId: TreeModel['id']; person: PersonModel; editMode: boolean}
const PersonInfoBlock = ({person, editMode}: PersonInfoBlockProps) => {
  const intl = useIntl()
  const firstLetters = useFirstLetters(`${person.first_name} ${person.last_name}`)
  const {ts} = useSessionTs()
  const avatar = useMemo(() => {
    if (person.avatar?.url) {
      return <AvatarBackground src={`${person.avatar.url}?ts=${ts}`} />
    }
    if (firstLetters) return <Initials>{firstLetters}</Initials>

    return <AvatarBackground src='https://my-engine.ru/modules/users/avatar.png' />
  }, [person.avatar, firstLetters, ts])

  const {hide} = useSubTreeModalStore()
  const {persons, updatePersons} = usePersonsStore()
  const handleDeleteSubTreeClick = (event) => {
    console.log('### handleDeleteSubTreeClick.person', person)
    event.preventDefault()
    event.stopPropagation()

    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        if (person.id) {
          // @ts-ignore
          editPersonAx(person.id, {...person, subtree: null, is_subtree: 0})
            .then((response) => {
              console.log('### person subtree have been deleted', response)
              hide()

              const personsClone = [...persons]
              const personIndex = personsClone.findIndex(({id}) => response.data.id === id)
              if (personIndex !== -1) {
                personsClone[personIndex] = {...personsClone[personIndex], ...response.data}
                updatePersons(personsClone, true)
              }
            })
            .catch((error) => {
              console.log('### person subtree error', error)
            })
        }
      }
    })
  }

  return (
    <Wrapper>
      <AvatarWrapper>{avatar}</AvatarWrapper>
      <NameStatus>
        <Name>
          {person.first_name} {person.last_name}
        </Name>

        <State>
          {intl.formatMessage({id: person.is_alive === 0 ? 'PERSON.DEAD' : 'PERSON.ALIVE'})}
        </State>
      </NameStatus>
      {editMode ? (
        <DeleteSubTreeButtonIcon onClick={handleDeleteSubTreeClick}>
          <TrashIcon height={20} />
        </DeleteSubTreeButtonIcon>
      ) : null}
    </Wrapper>
  )
}

export default React.memo(PersonInfoBlock)

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.3);

  position: absolute;
  top: 20px;
  left: 20px;

  padding: 10px;
  border-radius: 12px;

  border: 1px solid rgba(255, 255, 255, 0.4);

  display: flex;

  height: 90px;
`

const AvatarWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid white;

  overflow: hidden;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const Initials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
  height: 100%;

  font-size: ${70 * 0.3}px;
  color: #fff;
  font-weight: 500;
`

const NameStatus = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  justify-content: center;
  align-items: flex-start;

  margin-left: 20px;
`

const Name = styled.div`
  color: black;
  font-size: 16px;
  font-weight: 600;

  width: 100%;
  text-align: center;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
`

const State = styled.span`
  color: black;
  font-size: 14px;
  font-weight: 400;

  text-align: center;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
`

const DeleteSubTreeButtonIcon = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: crimson;

  i {
    font-size: 20px !important;
  }

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`
