import React, {useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {Modal} from 'react-bootstrap'
import {create} from 'zustand'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {useIntl} from 'react-intl'
import clsx from 'clsx'

import {KTIcon} from '@_metronic/helpers'
import useSessionTs from '@hooks/useSessionTs'

import {usePersonsStore} from '@store/persons'
import {useTreesStore} from '@store/trees'
import {useRelationsStore, Relation as RelationIconType} from '@pages/RelationsPage/store'

import {RelationsEnum} from '@components/organisms/Tree/constants'
import {PersonModel} from '@api/models'
import {getPagedSims} from '@api/requests'

// import {ReactComponent as PartnerIcon} from '@assets/icons/svg/relations/partner.svg'
// import {ReactComponent as MarriedIcon} from '@assets/icons/svg/relations/married.svg'
// import {ReactComponent as DivorcedIcon} from '@assets/icons/svg/relations/divorced.svg'
// import {ReactComponent as FriendsIcon} from '@assets/icons/svg/relations/friends.svg'
// import {ReactComponent as EngagedIcon} from '@assets/icons/svg/relations/engaged.svg'
// import {ReactComponent as FormerPartnerIcon} from '@assets/icons/svg/relations/formerPartner.svg'
// import {ReactComponent as SiblingsIcon} from '@assets/icons/svg/relations/siblings.svg'
// import {ReactComponent as WidowIcon} from '@assets/icons/svg/relations/widow.svg'
// import {ReactComponent as FlirtIcon} from '@assets/icons/svg/relations/flirt.svg'
// import {ReactComponent as OtherIcon} from '@assets/icons/svg/relations/other.svg'
// import {ReactComponent as UfoIcon} from '@assets/icons/svg/relations/ufo.svg'

import {RelationType} from '@app/types'

const MySwal = withReactContent(Swal)

export const relationNamesMap = {
  [RelationsEnum.partner]: 'RELATIONS.PARTNER',
  [RelationsEnum.married]: 'RELATIONS.MARRIED',
  [RelationsEnum.divorced]: 'RELATIONS.DIVORCED',
  [RelationsEnum.friends]: 'RELATIONS.FRIENDS',
  [RelationsEnum.engaged]: 'RELATIONS.ENGAGED',
  [RelationsEnum.formerPartner]: 'RELATIONS.FORMER',
  [RelationsEnum.siblings]: 'RELATIONS.SUBLINGS',
  [RelationsEnum.widow]: 'RELATIONS.WIDOW',
  [RelationsEnum.flirt]: 'RELATIONS.FLIRT',
  [RelationsEnum.other]: 'RELATIONS.OTHER',
  [RelationsEnum.ufo]: 'RELATIONS.UFO',
}

enum RelationTab {
  builtin,
  custom,
}

export enum PersonsModalMode {
  personForNodeSelect = 'personForNodeSelect',
  partnerSelect = 'partnerSelect',
  partnerRelationUpdate = 'partnerRelationUpdate',
  parentSelect = 'parentSelect',
  petSelect = 'petSelect',
  petParentSelect = 'petParentSelect',
  friendSelect = 'friendSelect',
}

type Payload = {
  treeId?: number
  nodeId?: number
  personId?: number
  personType?: number
  partnerId?: number
  relationType?: RelationsEnum
  personSelect?: (person: PersonModel) => void
  partnerSelect?: ({
    person,
    relationType,
  }: {
    person: PersonModel
    relationType: RelationsEnum
  }) => void
  relationSelect?: (relationType: RelationsEnum) => void
}

type PersonsModalStore = {
  opened: boolean
  mode: PersonsModalMode | null
  payload: Payload | null
  show: (values: Payload, mode: PersonsModalMode) => void
  hide: () => void
}
export const usePersonsModalStore = create<PersonsModalStore>((set) => ({
  opened: false,
  payload: null,
  mode: null,
  show: (payload, mode) => set((state) => ({...state, payload, opened: true, mode})),
  hide: () =>
    set((state) => ({...state, opened: false, selectedPerson: null, payload: null, mode: null})),
}))

const PersonsModal = () => {
  const intl = useIntl()

  const {opened, hide, payload, mode} = usePersonsModalStore()
  const [persons, setPersons] = useState<PersonModel[]>([])
  const {
    persons: personsInStore,
    updatePersons,
    addPartnerToPerson,
    addRelationToPerson,
  } = usePersonsStore()
  const {assignPersonToNode} = useTreesStore()

  const [searchPersons, setSearchPersons] = useState('')
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setPage(0)
    setPersons([])
  }, [searchPersons])

  useEffect(() => {
    if (opened) {
      setLoading(true)
      // console.log('### mode', mode)
      const timeout = setTimeout(() => {
        console.log('### getPagedSims', page)
        getPagedSims({
          page,
          perPage: 10,
          fields:
            'id,first_name,last_name,avatar,type,is_alive,is_subtree,pets,parents,partners,params',
          name: searchPersons.length >= 3 ? searchPersons : '',
          type:
            mode === PersonsModalMode.petSelect || mode === PersonsModalMode.petParentSelect
              ? 1
              : undefined,
        })
          .then((response) => {
            // console.log('### response.data', response.data, page)
            setTotalPages(response.headers['x-pagination-page-count'] * 1)

            const filteredPersons = response.data.filter(
              ({type}) =>
                !(
                  type === 1 &&
                  // @ts-ignore
                  ![PersonsModalMode.petSelect, PersonsModalMode.petParentSelect].includes(mode)
                )
            )

            setPersons((state) => [...state, ...filteredPersons])
          })
          .catch((error) => console.log('### error', error))
          .finally(() => {
            setLoading(false)
          })
      }, 300)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [opened, page, searchPersons])

  const handlePersonSelect = (person) => {
    console.log('### handlePersonSelect', person)
    if (payload) {
      const {treeId, nodeId, personSelect} = payload

      if (treeId && nodeId) {
        const extendStorePersonsByPersonRelations = (persons) => {
          // console.log('### extendStorePersonsByPersonRelations')
          // check which persons (pet/parents/partners) not in persons
          const personIds = persons.map(({id}) => id)
          const partnersPetAndParentIds: number[] = [
            ...(person.pets || []),
            ...(person.parents || []),
            ...(person.partners || []).map(({partner_id}) => partner_id),
          ].filter((id) => !personIds.includes(id))

          // console.log('### partnersPetAndParentIds', partnersPetAndParentIds)

          // require and update
          if (partnersPetAndParentIds.length > 0) {
            getPagedSims({
              ids: partnersPetAndParentIds,
              fields: 'id,first_name,last_name,avatar,is_alive,is_subtree,pets,parents,partners',
            }).then((response) => {
              const personPersons = response.data as PersonModel[]

              updatePersons([...persons, ...personPersons], true)
            })
          }
        }

        if (!personsInStore.find(({id}) => id === person.id)) {
          // console.log('### updatePersons.person+', person)
          updatePersons([...personsInStore, person], true, extendStorePersonsByPersonRelations)
        } else {
          extendStorePersonsByPersonRelations(personsInStore)
        }

        assignPersonToNode(treeId, nodeId, person.id)
      } else if (personSelect) {
        personSelect(person)
      }
    }

    hide()
  }

  const [relationType, setRelationType] = useState(payload?.relationType || RelationsEnum.partner)
  const handlePartnerSelect = (person) => {
    console.log('### handlePartnerSelect', person, payload)
    if (payload) {
      const {personId, partnerSelect} = payload
      if (partnerSelect) {
        partnerSelect({person, relationType})
      } else if (personId) {
        if (!personsInStore.find(({id}) => id === person.id)) {
          console.log('### updatePersons.person+', person)
          updatePersons([person, ...personsInStore], true)
        }

        addPartnerToPerson(personId, person.id, relationType, (error = '') => {
          MySwal.fire({
            title: intl.formatMessage({id: 'ADD.PARTNER.ERROR'}),
            text: error,
            icon: 'error',
            heightAuto: false,
          })
        })
      }
    }

    hide()
  }

  const handleRelationSelect = (relationType: RelationsEnum) => {
    console.log('### handleRelationSelect', relationType, payload)
    if (payload) {
      const {personId, partnerId, relationSelect} = payload

      if (relationSelect) {
        // console.log('### relationSelect', relationType)
        relationSelect(relationType)
      } else if (personId && partnerId && relationType) {
        addPartnerToPerson(personId, partnerId, relationType, (error = '') => {
          MySwal.fire({
            title: intl.formatMessage({id: 'EDIT.RELATION.ERROR'}),
            text: error,
            icon: 'error',
            heightAuto: false,
          })
        })
      }
    }
    hide()
  }

  const handleParentSelect = (parent: PersonModel) => {
    console.log('### handleParentSele.ct')
    if (payload) {
      const {personId} = payload

      if (!personsInStore.find(({id}) => id === parent.id)) {
        // console.log('### updatePersons.person+', parent)
        updatePersons([...personsInStore, parent], true)
      }

      addRelationToPerson(
        {personId: personId!, relationId: parent.id, relationType: RelationType.parent},
        (error = '') => {
          MySwal.fire({
            title: intl.formatMessage({id: 'ADD.PARENT.ERROR'}),
            text: error,
            icon: 'error',
            heightAuto: false,
          })
        }
      )
    }
    hide()
  }

  const handlePetSelect = (pet: PersonModel) => {
    if (payload) {
      const {personId} = payload

      if (!personsInStore.find(({id}) => id === pet.id)) {
        // console.log('### updatePersons.person+', pet)
        updatePersons([...personsInStore, pet], true)
      }

      addRelationToPerson(
        {personId: personId!, relationId: pet.id, relationType: RelationType.pet},
        (error = '') => {
          MySwal.fire({
            title: intl.formatMessage({id: 'ADD.PET.ERROR'}),
            text: error,
            icon: 'error',
            heightAuto: false,
          })
        }
      )
    }
    hide()
  }

  const handleFriendSelect = (friend: PersonModel) => {
    if (payload) {
      const {personId} = payload

      if (!personsInStore.find(({id}) => id === friend.id)) {
        updatePersons([...personsInStore, friend], true)
      }

      addRelationToPerson(
        {personId: personId!, relationId: friend.id, relationType: RelationType.friend},
        (error = '') => {
          MySwal.fire({
            title: intl.formatMessage({id: 'ADD.FRIEND.ERROR'}),
            text: error,
            icon: 'error',
            heightAuto: false,
          })
        }
      )
    }
    hide()
  }

  useEffect(() => {
    if (!opened) {
      setRelationType(RelationsEnum.partner)
      setSearchPersons('')
      setPage(0)
      setPersons([])
    }
  }, [opened])

  useEffect(() => {
    if (payload?.relationType) {
      setRelationType(payload?.relationType)
    }
  }, [payload])

  const handlePersonClick = (relation: PersonModel) => {
    if (mode === PersonsModalMode.partnerSelect) {
      handlePartnerSelect(relation)
    }
    if (mode === PersonsModalMode.personForNodeSelect) {
      handlePersonSelect(relation)
    }
    if (mode === PersonsModalMode.parentSelect || mode === PersonsModalMode.petParentSelect) {
      handleParentSelect(relation)
    }
    if (mode === PersonsModalMode.petSelect) {
      handlePetSelect(relation)
    }
    if (mode === PersonsModalMode.friendSelect) {
      handleFriendSelect(relation)
    }
  }

  const handleRelationClick = (relationType: RelationsEnum) => {
    setRelationType(relationType)

    if (mode === PersonsModalMode.partnerRelationUpdate) {
      handleRelationSelect(relationType)
    }
  }

  const headerTitle = useMemo(() => {
    if (mode === PersonsModalMode.personForNodeSelect) {
      return intl.formatMessage({id: 'GET.SIM'})
    }
    if (mode === PersonsModalMode.partnerSelect) {
      return intl.formatMessage({id: 'GET.PARTNER'})
    }
    if (mode === PersonsModalMode.partnerRelationUpdate) {
      return intl.formatMessage({id: 'CHANGE.TYPE'})
    }
    if (mode === PersonsModalMode.parentSelect || mode === PersonsModalMode.petParentSelect) {
      return intl.formatMessage({id: 'GET.PARENT'})
    }
    if (mode === PersonsModalMode.petSelect) {
      return intl.formatMessage({id: 'GET.PET'})
    }
    if (mode === PersonsModalMode.friendSelect) {
      return intl.formatMessage({id: 'GET.FRIEND'})
    }
  }, [mode])

  const showRelationsBlock =
    mode && [PersonsModalMode.partnerSelect, PersonsModalMode.partnerRelationUpdate].includes(mode)

  const showPersonsList =
    mode &&
    [
      PersonsModalMode.personForNodeSelect,
      PersonsModalMode.partnerSelect,
      PersonsModalMode.parentSelect,
      PersonsModalMode.petSelect,
      PersonsModalMode.petParentSelect,
      PersonsModalMode.friendSelect,
    ].includes(mode)

  const {ts} = useSessionTs()

  const handleScroll = (event) => {
    if (event.target.scrollHeight - event.target.scrollTop <= event.target.clientHeight + 10) {
      if (!loading && totalPages !== page) {
        setPage((prevPage) => prevPage + 1)
      }
    }
  }

  const [activeRelationsTab, setActiveRelationsTab] = useState(RelationTab.builtin)

  const {relations: remoteRelations, loadRelations} = useRelationsStore()
  useEffect(() => {
    loadRelations()
  }, [])

  // const filteredRelations = relations?.filter((relation: Relation) => {
  //   return !relationNamesMap[relation.status]
  // })
  const basicRelations = useMemo(() => {
    return remoteRelations?.filter((relation: RelationIconType) => {
      return relationNamesMap[relation.status]
    })
  }, [remoteRelations])

  const customRelations = useMemo(() => {
    return remoteRelations?.filter((relation: RelationIconType) => {
      return !relationNamesMap[relation.status]
    })
  }, [remoteRelations])

  const filteredRelations = useMemo(() => {
    return activeRelationsTab === RelationTab.builtin ? basicRelations : customRelations
  }, [basicRelations, customRelations, activeRelationsTab])

  return (
    <Wrapper
      id='select-person'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered'
      show={opened}
      backdrop
      onHide={hide}
    >
      <div className='modal-header'>
        <h2 className='mb-0'>{headerTitle}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={hide}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>
      <Content className='modal-body'>
        {showRelationsBlock ? (
          <>
            <ul className='nav nav-tabs nav-tabs-line mb-5 mt-5 ps-4'>
              <li className='nav-item'>
                <a
                  className={clsx('nav-link', {active: activeRelationsTab === RelationTab.builtin})}
                  data-toggle='tab'
                  href='#'
                  onClick={() => setActiveRelationsTab(RelationTab.builtin)}
                >
                  <span className='nav-text'>{intl.formatMessage({id: 'EMBEDDED'})}</span>
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx('nav-link', {active: activeRelationsTab === RelationTab.custom})}
                  data-toggle='tab'
                  href='#'
                  onClick={() => setActiveRelationsTab(RelationTab.custom)}
                >
                  <span className='nav-text'>{intl.formatMessage({id: 'CUSTOM'})}</span>
                </a>
              </li>
            </ul>

            <RelationTypeSelectWrapper>
              {filteredRelations?.map(({id, status, file, base64, name_en, name_ru}) => {
                const iconsSrc = file?.url || base64
                const relationName = relationNamesMap[status]
                  ? intl.formatMessage({id: relationNamesMap[status]})
                  : name_en || name_ru || status

                return (
                  <Relation
                    key={id}
                    className='btn btn-secondary'
                    onClick={() => handleRelationClick(status as RelationsEnum)}
                  >
                    <RelationIconWrapper>
                      {activeRelationsTab === RelationTab.custom ? (
                        <RelationIconBackground
                          src={require('@assets/backgrounds/chess-background.png')}
                        />
                      ) : (
                        <RelationIconBackground2 />
                      )}

                      {iconsSrc ? <RelationIcon src={iconsSrc} /> : null}
                    </RelationIconWrapper>
                    <RelationName $active={status === relationType}>{relationName}</RelationName>
                  </Relation>
                )
              })}
            </RelationTypeSelectWrapper>
          </>
        ) : null}

        {showPersonsList ? (
          <>
            <PersonSearchInputWrapper>
              <input
                type='text'
                data-kt-user-table-filter='search'
                className='form-control mt-4 mb-4'
                placeholder={intl.formatMessage({id: 'PERSON.SEARCH'})}
                value={searchPersons}
                onChange={(e) => setSearchPersons(e.currentTarget.value)}
              />
            </PersonSearchInputWrapper>
            <List className='p-4' onScroll={handleScroll}>
              {persons.map((person) => (
                <Person
                  key={person.id}
                  className='btn btn-sm btn-light-primary fw-bolder'
                  onClick={() => handlePersonClick(person)}
                >
                  <Avatar
                    src={`${person.avatar?.url}?ts=${ts}`}
                    alt={`avatar-${person.avatar?.id}`}
                    $invisible={!person.avatar}
                  />
                  <span className='fs-5 ms-2'>
                    {person.first_name} {person.last_name}
                  </span>
                </Person>
              ))}
              {loading ? (
                <div className='indicator-progress mt-5 d-flex justify-content-center'>
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </div>
              ) : null}
            </List>
          </>
        ) : null}
      </Content>
    </Wrapper>
  )
}

export default React.memo(PersonsModal)

const Wrapper = styled(Modal)`
  .modal-dialog {
    @media (min-width: 800px) {
      min-width: 500px;
    }
  }
`

const Content = styled.div`
  padding: 0;
`

const RelationTypeSelectWrapper = styled.div`
  padding: 13px 17px 13px 13px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-auto-rows: 64px; */
  gap: 4px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Relation = styled.button`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7px !important;
`

const RelationName = styled.span<{$active: boolean}>`
  opacity: ${({$active}) => ($active ? 1 : 0.6)};

  padding-left: 10px;
  width: 100%;

  text-align: start;
`

const RelationIconWrapper = styled.div`
  width: 35px !important;
  height: 35px !important;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  flex-shrink: 0;
`

const RelationIcon = styled.img`
  height: 25px;
  width: 25px;
  object-fit: cover;

  z-index: 1;
`

const RelationIconBackground = styled(RelationIcon)`
  height: 35px;
  width: 35px;
  position: absolute;
  opacity: 0.1;
  z-index: 0;

  border-radius: 2px;
`

const RelationIconBackground2 = styled.div`
  height: 35px;
  width: 35px;
  position: absolute;
  z-index: 0;

  border-radius: 50%;

  background-color: #3e97ffaa;
`

const RelationLabel = styled.span`
  text-align: left;
`

const PersonSearchInputWrapper = styled.div`
  padding-left: 14px;
  padding-right: 17px;
`

const List = styled.ul`
  height: 300px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  padding: 0 20px;
`

const Person = styled.button`
  margin-bottom: 10px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Avatar = styled.img<{$invisible: boolean}>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  visibility: ${({$invisible}) => ($invisible ? 'hidden' : 'visible')};
`
