import React, {Suspense, useEffect} from 'react'
import {Outlet, useSearchParams} from 'react-router-dom'

import useReferralString from './hooks/useReferralString'
import useSessionTs from '@hooks/useSessionTs'
import usePixelsInsert from './hooks/usePixelsInsert'
import {useSubscriptionModalStore} from './components/molecules/SubscriptionModal/SubscriptionModal'

import {I18nProvider} from '@_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '@_metronic/layout/core'
import {MasterInit} from '@_metronic/layout/MasterInit'
import {AuthInit} from '@modules/auth'
import {ThemeModeProvider} from '@_metronic/partials'

const App = () => {
  useReferralString()

  const {refreshTs} = useSessionTs()

  useEffect(() => {
    refreshTs()
  }, [])

  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const [searchParams] = useSearchParams()
  useEffect(() => {
    const getPremium = searchParams.get('getPremium')
    console.log('### getPremium', `"${getPremium}"`, typeof getPremium)

    if (typeof getPremium === 'string') {
      console.log('### LETS GO!')
      showSubscriptionModal()
    }
  }, [searchParams])

  usePixelsInsert()

  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <ThemeModeProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </ThemeModeProvider>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
    </>
  )
}

export {App}
